
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
}
