
import { getIdFromURL } from 'vue-youtube-embed'

export default {
  props: {
    url: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      videoThumbnail: null,
      isHover: false,
      modalIsOpen: false,
    }
  },

  computed: {
    youtubeVideoId() {
      return getIdFromURL(this.url)
    },
  },

  async mounted() {
    if (this.url) {
      this.videoThumbnail = `https://img.youtube.com/vi/${this.youtubeVideoId}/maxresdefault.jpg`
      const imagewidht = await this.getImgWidth()
      // sometimes the youtube max res image is not available
      // it will return instead an error image that has a width of exaclty 120
      // when it returs that error image it should get instead the hqdefault image
      if (imagewidht === 120) {
        this.videoThumbnail = `https://img.youtube.com/vi/${this.youtubeVideoId}/hqdefault.jpg`
      }
    }
  },

  methods: {
    getImgWidth() {
      return new Promise((resolve) => {
        const newImg = new Image()

        newImg.onload = function () {
          resolve(newImg.width)
        }

        newImg.src = this.videoThumbnail
      })
    },
  },
}
