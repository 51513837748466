// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/corner-circle.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/png/corner-circle.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bg-circle-cover[data-v-fcb8fe4e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100% 100%;background-size:200%;margin-bottom:-1px;bottom:-1px;transform:scaleX(-1.01)}@media (min-width:1024px){.bg-circle-cover[data-v-fcb8fe4e]{background-position:0 100%;background-size:cover;margin-right:0}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.bg-circle-cover[data-v-fcb8fe4e]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important}.slug-hero-container[data-v-fcb8fe4e]{width:100%!important}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
