
export default {
  components: {},

  props: {
    url: {
      type: String,
      required: true,
    },
    blank: {
      type: Boolean,
      default: false,
    },
    showAsButton: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
