import { render, staticRenderFns } from "./Resources.vue?vue&type=template&id=5644fccb&scoped=true&"
import script from "./Resources.vue?vue&type=script&lang=js&"
export * from "./Resources.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5644fccb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DownloadIcon: require('/vercel/path0/components/icons/DownloadIcon.vue').default,LinkIcon: require('/vercel/path0/components/icons/LinkIcon.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
