import { render, staticRenderFns } from "./JournalPageEnding.vue?vue&type=template&id=e524f64a&"
import script from "./JournalPageEnding.vue?vue&type=script&lang=js&"
export * from "./JournalPageEnding.vue?vue&type=script&lang=js&"
import style0 from "./JournalPageEnding.vue?vue&type=style&index=0&id=e524f64a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShareBlogpost: require('/vercel/path0/components/ShareBlogpost.vue').default,PlusButton: require('/vercel/path0/components/ui/PlusButton.vue').default,PersonDetailInfos: require('/vercel/path0/components/componentloader/elements/PersonDetailInfos.vue').default,AccordionAnimation: require('/vercel/path0/components/ui/AccordionAnimation.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,Journals: require('/vercel/path0/components/journal/Journals.vue').default})
