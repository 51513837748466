
import mapsSyle from '~/assets/maps/maps-style'

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      availableMarkerIcons: [
        'marker_teal.png',
        'marker_yellow.png',
        'marker_red.png',
        'marker_green.png',
      ],
      currentLocation: {},
      circleOptions: {},
      options: {
        styles: mapsSyle,
      },
      locations: null,
      mapStyle: [],
      clusterStyle: mapsSyle,
      infoWindowOpen: false,
      selectedMarker: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      centerPosition: {
        lat: 46.884199,
        lng: 8.323461,
      },
      google: null,
      states: {},
    }
  },

  computed: {
    statesArray() {
      return Object.keys(this.states)
        .map((key) => this.states[key])
        .sort((a, b) => {
          if (a.value === b.value) return 0
          if (a.value === 'open' || a.value === 'unobstructed') return -1
          return 1
        })
    },
  },

  async mounted() {
    await this.loadLocations()
    this.google = await this.$gmapApiPromiseLazy()
    this.$nextTick(() => {
      this.$refs.gmap.$mapPromise.then((map) => {
        const bounds = new this.google.maps.LatLngBounds()
        for (const m of this.locations) {
          bounds.extend(m)
        }
        map.fitBounds(bounds)
      })
    })
  },

  methods: {
    toggleInfoWindow(marker) {
      if (!marker.info) return
      if (this.selectedMarker === marker) {
        this.infoWindowOpen = !this.infoWindowOpen
      } else {
        this.selectedMarker = marker
        this.infoWindowOpen = true
      }
    },
    async loadLocations() {
      const { data } = await this.$axios.$get(
        `${this.$config.backendUrl}/api-statamic/collections/locations/entries`,
        {
          params: {
            'filter[category:in]': this.content.categories.map((c) => c.slug),
            limit: 1000,
          },
        }
      )

      this.states = data.reduce((prev, curr) => {
        prev[curr.state.key] = curr.state
        return prev
      }, {})

      this.locations = data.map((l) => {
        return {
          ...l,
          lng: Number(l.lng),
          lat: Number(l.lat),
          icon: this.getImageForState(l.state),
        }
      })
    },
    getImageForState(state) {
      const originalState = this.statesArray.find(
        (s) => s.value === state.value
      )
      const index = this.statesArray.indexOf(originalState)
      return this.availableMarkerIcons[index]
    },
  },
}
