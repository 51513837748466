
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
}
