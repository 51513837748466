
import Vue100vh from 'vue-100vh'
import { getYoutubeVideoId } from '~/utils'

export default {
  components: { Vue100vh },

  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      playy: 'paused',
      scrollY: null,
    }
  },

  computed: {
    videoId() {
      return getYoutubeVideoId(this.url)
    },
  },

  beforeMount() {
    this.preventScrolling(true)
  },

  beforeDestroy() {
    this.preventScrolling(false)
  },

  methods: {
    preventScrolling(yes) {
      const body = document.body

      if (yes) {
        this.scrollY = window.scrollY
        document.body.style.overflowY = 'hidden'
        document.body.style.left = 0
        document.body.style.right = 0
      } else {
        body.style.overflowY = 'auto'
        body.style.position = ''
        body.style.top = ''
        body.style.left = ''
        body.style.right = ''
        window.scrollTo(0, this.scrollY)
      }
    },

    ready(event) {
      this.player = event.target
    },

    playing(event) {
      this.playy = 'playing'
      this.player.getVideoData()
    },

    paused(event) {
      this.playy = 'paused'
      this.player.getVideoData()
    },

    play() {
      if (this.playy === 'playing') {
        this.player.pauseVideo()
      } else if (this.playy === 'paused') {
        this.player.playVideo()
      }
    },

    clear() {
      this.player.stopVideo()
      this.player.playVideo()
    },
  },
}
