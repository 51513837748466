
import { mapState } from 'vuex'

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    linkUrl: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
    linkTargetBlank: {
      type: Boolean,
      default: false,
    },
    isLeft: {
      type: Boolean,
      default: false,
    },
    image1: {
      type: Object,
      default: null,
    },
    image2: {
      type: Object,
      default: null,
    },
    image1Src: {
      type: String,
      default: null,
    },
    image2Src: {
      type: String,
      default: null,
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
    forceBgGreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('page', ['page']),
  },
}
