
import { mapMutations } from 'vuex'

export default {
  props: {
    path: {
      type: Array,
      required: true,
    },
  },
  methods: {
    ...mapMutations('navigation', ['toggleNav']),
  },
}
