
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      form: {
        categories: [],
      },
      categories: [],
      categoriesEnabled: false,
      genders: [
        {
          label: 'Herr',
          value: 'm',
        },
        {
          label: 'Frau',
          value: 'f',
        },
        {
          label: 'Neutrale Anrede',
          value: 'n',
        },
      ],
      submissionState: null,
    }
  },

  computed: {
    isPreview() {
      return this.$route.query.preview !== undefined
    },
    titleFirstname() {
      return this.form.firstname || '%Vorname%'
    },
    titleLastname() {
      return this.form.lastname || '%Nachname%'
    },
  },

  async mounted() {
    const { data } = await this.$axios.$get(
      'taxonomies/newsletter_categories/terms'
    )
    this.categories = data.map((c) => ({
      value: c.title,
      label: c.title,
    }))
    this.form.categories = data.map((c) => c.title)
  },

  methods: {
    subscribe() {
      if (!this.isLoading) {
        this.isLoading = true
        this.$axios
          .$post('newsletter/subscribe', this.form)
          .then(() => {
            this.submissionState = 'success'

            this.$fb.fbq('track', 'CompleteRegistration')
          })
          .catch(() => {
            this.submissionState = 'error'
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
}
