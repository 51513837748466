import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=3b3b1c66&scoped=true&"
import script from "./Accordion.vue?vue&type=script&lang=js&"
export * from "./Accordion.vue?vue&type=script&lang=js&"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=3b3b1c66&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b3b1c66",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Minus: require('/vercel/path0/components/icons/Minus.vue').default,Plus: require('/vercel/path0/components/icons/Plus.vue').default,HtmlText: require('/vercel/path0/components/componentloader/HtmlText.vue').default})
