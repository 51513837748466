
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    withOpacity: {
      type: Boolean,
      default: null,
    },
    disableAnimation: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    beforeEnter(el) {
      el.style.maxHeight = '0'

      if (this.withOpacity) {
        el.style.opacity = 0
      }
    },
    enter(el) {
      el.style.maxHeight = el.scrollHeight + 'px'

      if (this.withOpacity) {
        el.style.opacity = 1
      }
    },
    afterEnter(el) {
      el.style.maxHeight = 'unset'
    },
    beforeLeave(el) {
      el.style.maxHeight = el.scrollHeight + 'px'

      // for some strange reason the maxHeigh has to be set twice, otherwise it can lead to bugs
      this.$nextTick(() => {
        el.style.maxHeight = el.scrollHeight + 'px'
      })

      if (this.withOpacity) {
        el.style.opacity = 1
      }
    },
    leave(el) {
      this.$nextTick(() => {
        el.style.maxHeight = '0'
      })

      if (this.withOpacity) {
        el.style.opacity = 0
      }
    },
  },
}
