
export default {
  components: {},

  props: {
    quote: {
      type: Object,
      required: true,
    },

    isLeft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
