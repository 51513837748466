
export default {
  props: {
    page: {
      type: Object,
      default: null,
    },
  },

  created() {
    this.$nuxt.$emit('set-white-background', true)
  },

  beforeDestroy() {
    this.$nuxt.$emit('set-white-background', false)
  },
}
