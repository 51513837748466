
export default {
  props: {
    title: {
      type: String,
      default: null,
    },

    persons: {
      type: Array,
      default: () => [],
    },
  },
}
