
export default {
  components: {},

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    linkUrl: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
  },
}
