
export default {
  components: {},

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    accordion: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: 0,
      cols: [],
      amountOfCols: 3,
    }
  },

  watch: {
    items() {
      this.open = 0
      this.$nextTick(() => {
        this.drawColums()
      })
    },
  },

  mounted() {
    this.runMansonry()
    window.addEventListener('resize', this.runMansonry)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.runMansonry)
  },

  methods: {
    async runMansonry() {
      // set amount of lines by screen size

      if (this.items.length === 1) this.amountOfCols = 1
      if (this.items.length === 2) this.amountOfCols = 2
      else this.amountOfCols = 3

      if (window.innerWidth < 1024) {
        this.amountOfCols = 2
        this.screenSize = 'md'
      }
      if (window.innerWidth < 768) {
        this.amountOfCols = 1
      }

      // apply element by col
      this.cols = []
      for (let i = 0; i < this.amountOfCols; i++) {
        this.cols.push({
          element: null,
          items: [],
        })
        await this.waitTick()
        const elements = [
          ...this.$refs.masonryContent.getElementsByClassName('masonry-col'),
        ]
        this.cols[i].element = elements[i]
      }
      this.drawColums()
    },
    async drawColums() {
      if (!this.$refs.masonryContent) {
        return
      }
      // remove all items from all cols
      this.cols = this.cols.map((c) => ({
        element: c.element,
        items: [],
      }))
      await this.waitTick()

      const items = this.items.filter((i) => i)
      for (let i = 0; i < items.length; i++) {
        // sort cols by smallest height
        const smallestCol = [...this.cols].sort((a, b) => {
          return a.element.clientHeight - b.element.clientHeight
        })[0]
        const index = this.cols.indexOf(smallestCol)
        this.cols[index].items.push(items[i])
        await this.waitTick()
      }
    },
    toggleOpen(index) {
      if (this.open === index) {
        this.open = null
      } else {
        this.open = index
      }
    },
    waitTick() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve()
        })
      })
    },
  },
}
