// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/raisenow-error-icon.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#tamaro-widget.tamaro-widget{background-color:transparent!important}#tamaro-widget.tamaro-widget #recipient-information-label{font-weight:500!important;margin-top:20px!important}#tamaro-widget.tamaro-widget .icon-error{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")!important}.no-purposes #tamaro-widget.tamaro-widget .payment-purposes>.main .purposes{display:none}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
