
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      internalValue: this.value,
    }
  },

  watch: {
    internalValue() {
      this.$emit('input', this.internalValue)
    },
    value() {
      this.internalValue = this.value
    },
  },
}
