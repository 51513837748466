
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    linkUrl: {
      type: String,
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
    donationDetails: {
      type: String,
      default: null,
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      checked: null,
      submit: null,
      randomDonationValue: '',
    }
  },

  computed: {
    donateLink() {
      return {
        path: this.linkUrl || '/spenden',
        query: {
          'rnw-amount':
            this.randomDonationValue || this.options[this.checked]?.amount,
          donationOptions: this.options
            .filter((o) => !o.enter_custom_amount)
            .map((a) => a.amount),
        },
      }
    },
  },

  methods: {
    resetInput() {
      this.randomDonationValue = ''
    },

    noDonation() {
      this.submit = null
    },
  },
}
