
import { getYoutubeVideoId } from '~/utils'

export default {
  components: {},

  props: {
    content: {
      type: Object,
      required: true,
    },

    isRight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isHover: false,
      modalIsOpen: false,
    }
  },

  computed: {
    videoId() {
      return getYoutubeVideoId(this.content.video_url)
    },
  },

  methods: {
    openModal() {
      this.modalIsOpen = true
    },

    closeModal() {
      this.modalIsOpen = false
    },
  },
}
