
export default {
  props: {
    image: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    mobileTitleBottom: {
      type: Boolean,
      default: false,
    },
  },
}
