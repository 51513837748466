import { render, staticRenderFns } from "./GiftDonationForm.vue?vue&type=template&id=04a31b08&scoped=true&"
import script from "./GiftDonationForm.vue?vue&type=script&lang=js&"
export * from "./GiftDonationForm.vue?vue&type=script&lang=js&"
import style0 from "./GiftDonationForm.vue?vue&type=style&index=0&id=04a31b08&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a31b08",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextField: require('/vercel/path0/components/ui/forms/TextField.vue').default,FormBox: require('/vercel/path0/components/ui/FormBox.vue').default,Checkbox: require('/vercel/path0/components/ui/forms/Checkbox.vue').default,RadioGroup: require('/vercel/path0/components/ui/forms/RadioGroup.vue').default})
