
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    items() {
      return this.content.items
    },
  },

  methods: {
    downloadUrl(file) {
      if (!file) return

      return `${process.env.apiUrl}/documents/download?id=${file.id}`
    },
  },
}
