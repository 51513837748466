
import { mapState } from 'vuex'
import { getTextFromHtml } from '~/utils'

export default {
  computed: {
    ...mapState('page', ['page']),
    currentUrl() {
      return process.env.appUrl + this.$route.fullPath
    },
    emailSubject() {
      return encodeURIComponent('Stiftung Denk an mich')
    },
    emailBody() {
      return encodeURIComponent(
        `${getTextFromHtml(this.page.page_title) || this.page.title}\n${
          this.currentUrl
        }`
      )
    },
  },
}
