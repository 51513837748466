
import { removePTags } from '~/utils'

export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    path: {
      type: Array,
      required: true,
    },
  },

  computed: {
    heroEntry() {
      return this.content.hero_entry
    },

    image() {
      if (this.heroEntry) {
        return this.heroEntry.image
      }
      return this.content.image
    },

    text() {
      if (this.heroEntry) {
        return this.heroEntry.text
      }

      return this.content.text
    },

    pageTitle() {
      return removePTags(
        this.heroEntry ? this.heroEntry.page_title : this.content.page_title
      )
    },

    displayDate() {
      return this.content.date && (this.content.display_date || this.isJournal)
    },
  },
}
