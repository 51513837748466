
import { animate } from 'motion'

export default {
  data() {
    return {
      candleHeadHovered: false,
    }
  },

  head() {
    return {
      script: [
        {
          src: 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/Observer.min.js',
        },
      ],
    }
  },

  async mounted() {
    await this.$nextTick()

    // eslint-disable-next-line
    gsap.registerPlugin(Observer) 

    this.$nuxt.$on('candle-head-hovered', (value) => {
      this.candleHeadHovered = value
    })

    this.initMouseFollower()
  },

  methods: {
    initMouseFollower() {
      const cursor = this.$el
      const candleContainer = document.querySelector('.candle-container')

      // when the mouse enters the candle container, we want to show the mouse follower
      candleContainer.addEventListener('mouseenter', () => {
        this.showMouseFollower()
      })

      // when the mouse leaves the candle container, we want to hide the mouse follower
      candleContainer.addEventListener('mouseleave', () => {
        this.hideMouseFollower()
      })

      // when the mouse moves, we want to move the mouse follower
      document.addEventListener('mousemove', (e) => {
        cursor.style.left = e.pageX - cursor.clientWidth / 2 + 'px'
        cursor.style.top = e.pageY - cursor.clientHeight / 2 + 'px'
      })
    },

    showMouseFollower() {
      const el = document.querySelector('.flame-mouse-follower')

      if (!el) return

      animate('.flame-mouse-follower', {
        opacity: 1,
        scale: 1,
      })
    },

    hideMouseFollower() {
      const el = document.querySelector('.flame-mouse-follower')

      if (!el) return

      animate(
        '.flame-mouse-follower',
        {
          opacity: 0,
          scale: 0.4,
        },
        {
          duration: 0.2,
        }
      )

      this.$emit('left-zone', true)
    },
  },
}
