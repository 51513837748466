
export default {
  components: {},

  props: {
    download: {
      type: Object,
      required: true,
    },

    noText: {
      type: Boolean,
      default: false,
    },

    hasData: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: null,
    },

    large: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fileSize() {
      if (this.download.size_kb < 1000) {
        return `${this.download.size_kb} KB`
      }
      return `${this.download.size_mb} MB`
    },
    downloadUrl() {
      return `${process.env.apiUrl}/documents/download?id=${this.download.id}`
    },
  },

  methods: {},
}
