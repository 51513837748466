
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { hover: [] }
  },
  methods: {},
}
