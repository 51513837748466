
export default {
  props: {
    isWhite: {
      type: Boolean,
      default: false,
    },
    enableDonation: {
      type: Boolean,
      default: false,
    },
  },
}
