
export default {
  components: {},

  props: {
    checked: {
      type: Number,
      default: null,
    },
    donation: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
