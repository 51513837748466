
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    narrow: {
      type: Boolean,
      default: false,
    },
  },
}
