
export default {
  props: {
    person: {
      type: Object,
      default: null,
    },
    withoutPosition: {
      type: Boolean,
      default: false,
    },
  },
}
