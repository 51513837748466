
import isEqual from 'lodash/isEqual'

export default {
  props: {
    handle: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    open: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      computedStyle: {},
      internalValue: this.value,
    }
  },

  watch: {
    open() {
      if (this.open) {
        this.calculatePosition()
      }
    },

    internalValue() {
      if (!isEqual(this.internalValue, this.value)) {
        this.$emit('input', [...this.internalValue])
      }
    },

    value() {
      if (!isEqual(this.internalValue, this.value)) {
        this.internalValue = [...this.value]
      }
    },
  },

  mounted() {
    if (this.open) {
      this.calculatePosition()
    }
  },

  methods: {
    calculatePosition() {
      if (!this.$refs.container) return

      const containerRect = this.$refs.container.getBoundingClientRect()
      const bodyRect = document.body.getBoundingClientRect()

      const topOffset = containerRect.top - bodyRect.top

      this.computedStyle = {
        height: document.body.scrollHeight - topOffset + 'px',
      }
    },

    toggleTag(slug) {
      const exists = this.internalValue.includes(slug)

      if (exists) {
        const index = this.internalValue.indexOf(slug)
        this.internalValue.splice(index, 1)
      } else {
        this.internalValue.push(slug)
      }

      this.internalValue = [...this.internalValue]

      this.$nextTick(() => {
        this.$emit('change')
      })
    },

    isSelected(tag) {
      if (!this.internalValue || !this.internalValue.length) return false

      return this.internalValue.includes(tag.slug)
    },

    reset() {
      this.internalValue = []

      this.$nextTick(() => {
        this.$emit('change')
      })
    },
  },
}
