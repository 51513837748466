import { render, staticRenderFns } from "./DonationStory.vue?vue&type=template&id=2dc504da&scoped=true&"
import script from "./DonationStory.vue?vue&type=script&lang=js&"
export * from "./DonationStory.vue?vue&type=script&lang=js&"
import style0 from "./DonationStory.vue?vue&type=style&index=0&id=2dc504da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc504da",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Minus: require('/vercel/path0/components/icons/Minus.vue').default,Plus: require('/vercel/path0/components/icons/Plus.vue').default})
