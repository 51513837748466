
import { parseISO, format } from 'date-fns'
import { getTextFromHtml } from '~/utils'
export default {
  components: {},

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    longText: {
      type: Boolean,
      default: false,
    },
    pageItems: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    computedItems() {
      if (!this.pageItems) return this.items

      return this.items.map((i) => {
        let rubric
        if (i.collection === 'journal' || i.collection?.id === 'journal') {
          rubric =
            format(parseISO(i.date), 'dd.MM.yyyy') +
            (i.rubric ? ' - ' + i.rubric?.title : '')
        } else if (i.display_date) {
          rubric = format(parseISO(i.date), 'dd.MM.yyyy')
        }

        return {
          rubric,
          title: getTextFromHtml(i.page_title),
          text: i.text,
          image: i.image,
          link_url: i.url,
          link_text: 'Mehr erfahren',
        }
      })
    },
  },
}
