
export default {
  components: {},

  props: {
    persons: {
      type: Array,
      required: true,
    },

    hero: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {
    cardOrder(index) {
      return index + 1
    },
  },
}
