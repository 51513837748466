import { render, staticRenderFns } from "./ShareBlogpost.vue?vue&type=template&id=0df0550a&scoped=true&"
import script from "./ShareBlogpost.vue?vue&type=script&lang=js&"
export * from "./ShareBlogpost.vue?vue&type=script&lang=js&"
import style0 from "./ShareBlogpost.vue?vue&type=style&index=0&id=0df0550a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df0550a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FacebookRound: require('/vercel/path0/components/icons/FacebookRound.vue').default,Twitter: require('/vercel/path0/components/icons/Twitter.vue').default,Whatsapp: require('/vercel/path0/components/icons/Whatsapp.vue').default,Mail: require('/vercel/path0/components/icons/Mail.vue').default})
