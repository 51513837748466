
export default {
  components: {},

  props: {
    hasButton: {
      type: Boolean,
      default: false,
    },

    isPlaying: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {
    isPlayStyling: {
      get() {
        return this.isPlaying
      },
      set(newIsPlaying) {
        return newIsPlaying
      },
    },
  },

  methods: {
    togglePlay() {
      this.isPlayStyling = !this.isPlayStyling
      this.$emit('clicked')
    },
  },
}
