
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

export default {
  props: {
    page: {
      type: Object,
      default: () => ({}),
    },
    results: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      rubricsOpen: false,
      tagsOpen: false,
      doSearch: false,
      searchString: this.$route.query.search,
      selectedTags: this.getArrayQuery('tags'),
      selectedRubrics: this.getArrayQuery('rubrics'),
    }
  },

  computed: {
    updateRoute() {
      return debounce(() => {
        if (
          !isEqual(this.selectedTags, this.getArrayQuery('tags')) ||
          !isEqual(this.selectedRubrics, this.getArrayQuery('rubrics')) ||
          this.searchString !== this.$route.query.search
        ) {
          this.$router.replace({
            query: {
              tags: [...this.selectedTags],
              rubrics: [...this.selectedRubrics],
              search: this.searchString,
            },
          })
        }
      }, 100)
    },
  },

  watch: {
    rubricsOpen() {
      if (this.rubricsOpen) {
        this.tagsOpen = false
      }
    },

    tagsOpen() {
      if (this.tagsOpen) {
        this.rubricsOpen = false
      }
    },

    doSearch() {
      if (this.doSearch) {
        this.rubricsOpen = false
        this.tagsOpen = false
      }
    },

    searchString() {
      if (this.searchString) {
        this.selectedRubrics = []
        this.selectedTags = []
      }

      this.updateRoute()

      this.update()
    },

    selectedTags() {
      this.updateRoute()
    },

    selectedRubrics() {
      this.updateRoute()
    },
  },

  created() {
    if (this.searchString && this.searchString.length) {
      this.doSearch = true
    }
  },

  methods: {
    update() {
      this.$emit('update', {
        search: this.searchString,
        tags: this.selectedTags,
        rubrics: this.selectedRubrics,
      })
    },

    updateRubrics() {
      this.$nextTick(() => {
        this.selectedTags = []
        this.searchString = null

        this.update()
      })
    },

    updateTags() {
      this.$nextTick(() => {
        this.selectedRubrics = []
        this.searchString = null

        this.update()
      })
    },

    getArrayQuery(handle) {
      const queryValue = this.$route.query[handle]
      if (!queryValue) return []
      if (!Array.isArray(queryValue)) {
        return [queryValue]
      }
      return queryValue
    },

    handleSearchClose() {
      this.searchString = ''
      this.doSearch = false
    },
  },
}
