
export default {
  components: {},

  props: {
    person: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

    hero: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      value: '',
    }
  },

  methods: {
    checkHero(index) {
      if (this.hero === true) {
        return this.niceColor1(index)
      } else {
        return this.niceColor2(index)
      }
    },

    niceColor1(index) {
      if (index % 9 === 0) {
        return 'yellow'
      } else if (index % 8 === 0) {
        return 'red'
      } else if (index % 7 === 0) {
        return 'green'
      } else if (index % 6 === 0) {
        return 'yellow'
      } else if (index % 5 === 0) {
        return 'green'
      } else if (index % 4 === 0) {
        return 'red'
      } else if (index % 3 === 0) {
        return 'green'
      } else if (index % 2 === 0) {
        return 'red'
      } else if (index === 1) {
        return 'yellow'
      }
    },

    niceColor2(index) {
      if (index % 9 === 0) {
        return 'yellow'
      } else if (index % 8 === 0) {
        return 'red'
      } else if (index % 7 === 0) {
        return 'white'
      } else if (index % 6 === 0) {
        return 'yellow'
      } else if (index % 5 === 0) {
        return 'white'
      } else if (index % 4 === 0) {
        return 'red'
      } else if (index % 3 === 0) {
        return 'white'
      } else if (index % 2 === 0) {
        return 'red'
      } else if (index === 1) {
        return 'yellow'
      }
    },
  },
}
