
import debounce from 'lodash/debounce'
import { mapState } from 'vuex'

export default {
  props: {
    page: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      meta: {
        current_page: 1,
        total: this.page.total_journals,
      },
      journals: [...this.page.journals],
      isLoading: false,
      params: {},
    }
  },

  computed: {
    ...mapState('navigation', { navigation: 'pages' }),

    debounceUpdate() {
      return debounce(this.fetchJournals, 300)
    },
  },

  methods: {
    async loadMoreJournals() {
      this.isLoading = true
      const { data, meta } = await this.$axios.$get('journals', {
        params: {
          per_page: 12,
          page: this.meta.current_page + 1,
          ...this.params,
        },
      })
      this.journals.push(...data)
      this.meta = meta
      this.isLoading = false
    },

    async fetchJournals(params) {
      this.params = params

      this.isLoading = true
      const { data, meta } = await this.$axios.$get('journals', {
        params: {
          per_page: 12,
          ...this.params,
        },
      })
      this.journals = data
      this.meta = meta
      this.isLoading = false
    },
  },
}
