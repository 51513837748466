
export default {
  components: {},

  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: null,
    },
    tabs: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Suchen Sie nach Name, ID-Nummer oder einem Stichwort',
    },
  },

  data() {
    return {
      search: this.value,
      selectedTab: this.tabs && this.tabs[0] ? this.tabs[0].id : null,
    }
  },

  watch: {
    value(newValue) {
      this.search = newValue
    },
    search(search) {
      this.$emit('input', search)
      this.updateQuery(search)
    },
    selectedTab(tab) {
      this.$emit('selectedTab', tab)
    },
  },
  mounted() {
    const tabIndex = Number(this.$route.query.tab) || 0

    // user would like to have a link to link direct to a certain tab
    if (
      !Array.isArray(this.tabs) ||
      tabIndex < 0 ||
      tabIndex > this.tabs.length - 1
    ) {
      return
    }

    this.selectedTab = this.tabs[tabIndex].id
  },
  methods: {
    updateQuery(search) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          search: search || undefined,
        },
      })
    },
  },
}
