
import checkIfDonatorExistsInLocalStorageMixin from '~/mixins/checkIfDonatorExistsInLocalStorageMixin'

export default {
  mixins: [checkIfDonatorExistsInLocalStorageMixin],

  props: {
    companySlug: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      donationName: '',
      selected: 'idle',
      submitted: false,
      successMessage: 'Vielen Dank fürs Mitmachen!',
    }
  },

  computed: {
    isExamplePage() {
      return this.$route.path.includes('beispiel-firmenseite')
    },
  },

  watch: {
    async selected() {
      await this.$nextTick()

      if (this.selected === 'public') {
        setTimeout(() => {
          this.$refs.donationInput.focus()
        }, 300)
      }

      if (this.selected === 'anonymous') {
        this.submit()
      }
    },
  },

  mounted() {
    setTimeout(() => {
      this.donatorExists()
    }, 1000)
  },

  methods: {
    submit() {
      try {
        this.$axios.post('/weihnachtsaktion/donate', {
          name: this.donationName || 'Anonym',
          company_slug: this.companySlug,
        })

        this.submitted = true

        if (this.isExamplePage) return

        this.saveDonatorToLocalStorage()
      } catch (e) {
        console.log(e)
      }
    },

    donatorExists() {
      const donator = this.checkIfDonatorExistsInLocalStorageMixin()

      if (donator) {
        if (donator.companySlug === this.companySlug) {
          this.submitted = true

          if (!donator.name) {
            this.donationName = 'Anonym mitgemacht'
          }

          this.donationName = donator.name
        }

        this.$nuxt.$emit('donated', 'isDonator')
      }
    },

    saveDonatorToLocalStorage() {
      localStorage.setItem(
        'donator',
        JSON.stringify({
          name: this.donationName,
          companySlug: this.companySlug,
          timestamp: Date.now(),
        })
      )
    },
  },
}
