import { render, staticRenderFns } from "./DonationStories.vue?vue&type=template&id=fd54c192&"
import script from "./DonationStories.vue?vue&type=script&lang=js&"
export * from "./DonationStories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterBar: require('/vercel/path0/components/FilterBar.vue').default,Masonry: require('/vercel/path0/components/Masonry.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
