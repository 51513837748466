
export default {
  components: {},

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    files: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      documents: [],
      meta: {},
    }
  },

  mounted() {
    if (!this.files) {
      this.loadDocuments()
    } else {
      this.documents = this.files
    }
  },

  methods: {
    async loadDocuments() {
      const { data, meta } = await this.getDocuments()
      this.documents = data
      this.meta = meta
    },
    async loadMoreDocuments() {
      document.activeElement.blur()
      const { data, meta } = await this.getDocuments(this.meta.current_page + 1)
      this.documents.push(...data)
      this.meta = meta
    },
    getDocuments(page = 1) {
      return this.$axios.$get('documents', {
        params: {
          per_page: 4,
          page,
          document_types: this.content.docuement_types.map((d) => d.slug),
        },
      })
    },
  },
}
