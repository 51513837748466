
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

export default {
  components: { VueSlickCarousel },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedPage: 0,
      settings: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        // focusOnSelect: true,
        dots: false,
      },
    }
  },

  methods: {
    change(page) {
      this.selectedPage = page
    },
  },
}
