import { render, staticRenderFns } from "./MediumArticles.vue?vue&type=template&id=51f484d6&"
import script from "./MediumArticles.vue?vue&type=script&lang=js&"
export * from "./MediumArticles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MediumArticle: require('/vercel/path0/components/componentloader/elements/MediumArticle.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
