
export default {
  components: {},

  props: {
    person: {
      type: Object,
      required: true,
    },
  },
}
