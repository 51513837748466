import { render, staticRenderFns } from "./HomeHero.vue?vue&type=template&id=c9af9a8a&scoped=true&"
import script from "./HomeHero.vue?vue&type=script&lang=js&"
export * from "./HomeHero.vue?vue&type=script&lang=js&"
import style0 from "./HomeHero.vue?vue&type=style&index=0&id=c9af9a8a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9af9a8a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YoutubeModal: require('/vercel/path0/components/componentloader/YoutubeModal.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Play: require('/vercel/path0/components/icons/Play.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,Plus: require('/vercel/path0/components/icons/Plus.vue').default,Minus: require('/vercel/path0/components/icons/Minus.vue').default,SocialMediaButtons: require('/vercel/path0/components/ui/SocialMediaButtons.vue').default})
