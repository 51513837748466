import { render, staticRenderFns } from "./DonationBannerSimple.vue?vue&type=template&id=679eec5e&"
import script from "./DonationBannerSimple.vue?vue&type=script&lang=js&"
export * from "./DonationBannerSimple.vue?vue&type=script&lang=js&"
import style0 from "./DonationBannerSimple.vue?vue&type=style&index=0&id=679eec5e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Donation: require('/vercel/path0/components/icons/Donation.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
