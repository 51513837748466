import { render, staticRenderFns } from "./_.vue?vue&type=template&id=2a0ff68f&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomeHero: require('/vercel/path0/components/hero/HomeHero.vue').default,JournalHero: require('/vercel/path0/components/hero/JournalHero.vue').default,WeihnachtsaktionHero: require('/vercel/path0/components/hero/WeihnachtsaktionHero.vue').default,SlugHero: require('/vercel/path0/components/hero/SlugHero.vue').default,LandingPage: require('/vercel/path0/components/staticpages/LandingPage.vue').default,JournalPage: require('/vercel/path0/components/staticpages/JournalPage.vue').default,PodcastsPage: require('/vercel/path0/components/staticpages/PodcastsPage.vue').default,MediasPage: require('/vercel/path0/components/staticpages/MediasPage.vue').default,WeihnachtsaktionInfo: require('/vercel/path0/components/staticpages/WeihnachtsaktionInfo.vue').default,Weihnachtsaktion: require('/vercel/path0/components/staticpages/Weihnachtsaktion.vue').default,WeihnachtsaktionCompany: require('/vercel/path0/components/staticpages/WeihnachtsaktionCompany.vue').default,DonationStoriesPage: require('/vercel/path0/components/staticpages/DonationStoriesPage.vue').default,ComponentLoader: require('/vercel/path0/components/componentloader/ComponentLoader.vue').default,JournalPageEnding: require('/vercel/path0/components/journal/JournalPageEnding.vue').default})
