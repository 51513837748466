
// import MultiClamp from 'multi-clamp'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    accordion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isTruncated: false,
      originalHeight: null,
      mulitClamp: null,
    }
  },
  watch: {
    open() {
      this.switchTruncated()
    },
  },
  mounted() {
    this.switchTruncated()
    this.$nextTick(() => {
      this.calculateTruncated()
    })
    window.addEventListener('resize', this.calculateTruncated)
  },
  methods: {
    toggleOpen(e) {
      document.activeElement.blur()

      this.$emit('open', !this.open)
    },
    switchTruncated() {
      if (this.$refs.truncatedText) {
        if (!this.originalHeight) {
          this.originalHeight = 'calc(7rem * 1.9)'
        }
        if (this.open) {
          this.$refs.truncatedText.style['-webkit-line-clamp'] = 'unset'
          this.originalHeight = this.$refs.truncatedText.clientHeight + 'px'
          this.$refs.truncatedText.style.maxHeight =
            this.$refs.truncatedText.scrollHeight + 'px'
        } else {
          this.$refs.truncatedText.style.maxHeight = this.originalHeight
          setTimeout(() => {
            if (this.$refs.truncatedText) {
              this.$refs.truncatedText.style['-webkit-line-clamp'] = 7
            }
          }, 500)
        }
      }
    },
    calculateTruncated() {
      if (!this.$refs.truncatedText) {
        window.removeEventListener('resize', this.calculateTruncated)
        return
      }
      this.isTruncated =
        this.$refs.truncatedText.clientHeight <
        this.$refs.truncatedText.scrollHeight
    },
  },
}
