
import { mapState } from 'vuex'
import { getBreadCrumbsBySlug, getHeadForPage } from '~/utils'

export default {
  transition: 'fade',

  async asyncData({ route, store, error, req, $axios }) {
    const host = req ? req.headers.host : window.location.host.split(':')[0]
    const splitdomain = host.split('.')
    const isPreview = splitdomain[0] === 'preview'

    const page = await store
      .dispatch('page/loadPage', {
        path: route.path,
        isPreview,
        query: route.query,
      })
      .catch((err) => {
        error({
          statusCode: err.response.status,
          message: err.response.data?.message,
        })
      })

    return {
      page,
    }
  },

  head() {
    return this.page ? getHeadForPage(this.page) : {}
  },

  computed: {
    ...mapState('navigation', { navigation: 'pages' }),
    path() {
      const breadCrumbs = getBreadCrumbsBySlug(
        this.navigation,
        this.$route.path,
        this.page
      )

      return breadCrumbs
    },
  },
}
