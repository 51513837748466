import { render, staticRenderFns } from "./WeihnachtsaktionInfo.vue?vue&type=template&id=3dce0259&"
import script from "./WeihnachtsaktionInfo.vue?vue&type=script&lang=js&"
export * from "./WeihnachtsaktionInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentLoader: require('/vercel/path0/components/componentloader/ComponentLoader.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
