// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/border-b.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/png/border-b.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".homehero .bg-border-b[data-v-c9af9a8a]{bottom:-1px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:bottom}.homehero .bg-gradient[data-v-c9af9a8a]{background:linear-gradient(180deg,rgba(0,115,122,0) 30%,#00737a)}.homehero .bg-180[data-v-c9af9a8a]{background-size:180%}.more-anim[data-v-c9af9a8a]{overflow:hidden;transition:all .55s ease}.content-text[data-v-c9af9a8a]{word-wrap:break-word;-webkit-hyphens:auto;hyphens:auto}.is-trunc[data-v-c9af9a8a]{margin-bottom:.15rem;background:linear-gradient(90deg,rgba(216,238,227,0),#d8eee3 70%)}.more-btn[data-v-c9af9a8a]{bottom:-2.5rem;width:2.5rem;height:2.5rem;margin-top:-1.2rem;right:50%;bottom:0;transform:translate(50%,50%)}.more-btn .plus[data-v-c9af9a8a]{width:.5rem}.truncated-text[data-v-c9af9a8a]{transition:all .5s;max-height:15em}@media (min-width:1024px){.truncated-text[data-v-c9af9a8a]{max-height:7.5em}}@media (min-width:1024px){.more-btn[data-v-c9af9a8a]{width:3.125rem;height:3.125rem;margin-top:-1.55rem;transform:translateY(50%);right:66.6666%}.more-btn .plus[data-v-c9af9a8a]{width:.875rem}}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.ie-minus[data-v-c9af9a8a]{height:2px}.ie-plus[data-v-c9af9a8a]{height:14px}.ie-play[data-v-c9af9a8a]{height:20px}.bg-border-b[data-v-c9af9a8a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")!important}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
