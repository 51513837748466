import { render, staticRenderFns } from "./WeihnachtsaktionDonationForm.vue?vue&type=template&id=48bf2740&scoped=true&"
import script from "./WeihnachtsaktionDonationForm.vue?vue&type=script&lang=js&"
export * from "./WeihnachtsaktionDonationForm.vue?vue&type=script&lang=js&"
import style0 from "./WeihnachtsaktionDonationForm.vue?vue&type=style&index=0&id=48bf2740&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bf2740",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tick: require('/vercel/path0/components/icons/Tick.vue').default,TransitionExpand: require('/vercel/path0/components/TransitionExpand.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
