
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    mainBackgroundColor() {
      if (this.content.color?.key === 'green') {
        return 'bg-green-300'
      }

      return 'bg-teal-200'
    },
    accentBackgroundColor() {
      if (this.content.color?.key === 'green') {
        return 'bg-green-500'
      }

      return 'bg-teal-500'
    },
  },
}
