
import { getTextFromHtml } from '~/utils'
export default {
  props: {
    journal: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    title() {
      return getTextFromHtml(this.journal.page_title)
    },

    description() {
      return getTextFromHtml(this.journal.text)
    },
  },
}
