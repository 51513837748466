
import { removePTags } from '~/utils'

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
    path: {
      type: Array,
      required: true,
    },
  },

  computed: {
    pageTitle() {
      return removePTags(this.content.page_title)
    },

    linkButtons() {
      if (!this.content.link_buttons) return []

      return this.content.link_buttons.filter((b) => b.url && b.text)
    },

    isJournal() {
      return this.content.collection === 'journal'
    },

    displayDate() {
      return this.content.date && (this.content.display_date || this.isJournal)
    },
  },
}
