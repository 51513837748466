import { render, staticRenderFns } from "./DonationStoryForm.vue?vue&type=template&id=f8425dac&scoped=true&"
import script from "./DonationStoryForm.vue?vue&type=script&lang=js&"
export * from "./DonationStoryForm.vue?vue&type=script&lang=js&"
import style0 from "./DonationStoryForm.vue?vue&type=style&index=0&id=f8425dac&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8425dac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextField: require('/vercel/path0/components/ui/forms/TextField.vue').default,TextArea: require('/vercel/path0/components/ui/forms/TextArea.vue').default,FormBox: require('/vercel/path0/components/ui/FormBox.vue').default,FileSelectField: require('/vercel/path0/components/ui/forms/FileSelectField.vue').default,SelectField: require('/vercel/path0/components/ui/forms/SelectField.vue').default,RadioGroup: require('/vercel/path0/components/ui/forms/RadioGroup.vue').default,PaperPlane: require('/vercel/path0/components/icons/PaperPlane.vue').default,BannerWithImage: require('/vercel/path0/components/ui/BannerWithImage.vue').default})
