export default {
  methods: {
    checkIfDonatorExistsInLocalStorageMixin() {
      const donator = localStorage.getItem('donator')

      if (!donator) return false

      return JSON.parse(donator)
    },
  },
}
