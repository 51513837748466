
export default {
  components: {},

  props: {
    accordion: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      accordionOpen: this.accordion.open,
    }
  },

  computed: {},

  methods: {
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen
    },

    beforeEnter(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px'
    },
    afterEnter(el) {
      el.style.opacity = '1'
    },
    beforeLeave(el) {
      el.style.opacity = '1'
      el.style.height = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.opacity = '0'
      el.style.height = '0'
    },
  },
}
