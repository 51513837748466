
export default {
  transition: 'fade',

  props: {
    page: {
      type: Object,
      required: true,
    },
  },
}
