import { render, staticRenderFns } from "./YoutubeModal.vue?vue&type=template&id=f78c8996&"
import script from "./YoutubeModal.vue?vue&type=script&lang=js&"
export * from "./YoutubeModal.vue?vue&type=script&lang=js&"
import style0 from "./YoutubeModal.vue?vue&type=style&index=0&id=f78c8996&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Close: require('/vercel/path0/components/icons/Close.vue').default,Pause: require('/vercel/path0/components/icons/Pause.vue').default,Play: require('/vercel/path0/components/icons/Play.vue').default,Redo: require('/vercel/path0/components/icons/Redo.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
