
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    preSelectedTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      selectedTab: this.preSelectedTab,
      pdfData: null,
      generatedPdfUrl: null,
      isLoadingPdf: false,
      pdfError: false,
      sendGiftToRecipientOptions: [
        {
          label: 'Versand an meine Adresse',
          value: false,
        },
        {
          label: 'Versand direkt an die beschenkte Person',
          value: true,
        },
      ],
    }
  },
  computed: {
    pdfUrl() {
      return `${process.env.apiUrl}/donation-certificate-preview?firstname=${this.value.stored_recipient_firstname}&lastname=${this.value.stored_recipient_lastname}&amount=${this.value.amount}`
    },
  },
  watch: {
    selectedTab() {
      // scroll always to the top
      const rect = this.$refs.giftDonationFrom.getBoundingClientRect()
      window.scrollTo(0, rect.top + window.scrollY - 150)

      if (this.selectedTab === 1) {
        this.isLoadingPdf = true
        // pdf is returned as base_64, because otherwise there are x-frame-origion problems
        this.$axios
          .$get(this.pdfUrl)
          .then((response) => {
            this.generatedPdfUrl = `data:application/pdf;base64,${response}`
            this.pdfError = false
          })
          .catch(() => {
            this.pdfError = true
          })
          .finally(() => {
            this.isLoadingPdf = false
          })
      }
    },
  },
  methods: {
    goBack() {
      document.activeElement.blur()
      this.selectedTab--
    },
    submitForm() {
      this.$router.replace(
        {
          query: {
            ...this.$route.query,
            'rnw-amount': this.value.amount,
          },
        },
        () => {}
      )
      this.$emit('submit')
    },
  },
}
