
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      open: false,
    }
  },

  computed: {
    person() {
      return this.content.person
    },
  },
}
