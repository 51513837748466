
export default {
  props: {
    page: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSuccessMessage: false,
      formData: {
        button_text: 'Bestellung abschliessen',
        text_below_button:
          'Sie erhalten umgehend eine Bestellbestätigung per E-Mail. Ihre persönliche Landingpage wird innerhalb von 5 Arbeitstagen nach Bestellung aktiviert. Nach der Aktivierung erhalten Sie ebenfalls eine Bestätigung. Falls Sie später Änderungen an den Texten vornehmen möchten, können Sie uns dies per E-Mail an info@denkanmich.ch oder telefonisch unter 058 135 06 50 (Mo-Do jeweils vormittags) mitteilen.',
        submition_form: [this.page.form],
      },
    }
  },

  methods: {
    scrollIntoView() {
      this.showSuccessMessage = true

      const contentSection = document.getElementById('content-section')

      const elementRect = contentSection.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.pageYOffset
      const middlePosition = absoluteElementTop - window.innerHeight / 4

      window.scrollTo({
        top: middlePosition,
        behavior: 'smooth',
      })
    },
  },
}
