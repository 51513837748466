
import { mapState, mapMutations } from 'vuex'

export default {
  props: {
    track: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isPlaying: false,
      isHover: false,
      hasPlayed: false,
    }
  },

  computed: {
    ...mapState('player', ['playingTrack']),
    sourceType() {
      if (this.track.audio_file.extension === 'wav') {
        return 'audio/wav'
      } else if (this.track.audio_file.extension === 'ogg') {
        return 'audio/ogg'
      }
      return 'audio/mpeg'
    },

    downloadUrl() {
      return `${process.env.apiUrl}/documents/${this.track.audio_file.id}/download`
    },
  },

  methods: {
    ...mapMutations('player', ['setPlayingTrack']),
    play() {
      this.hasPlayed = true
      this.setPlayingTrack(this.track.id)
      const a = this.$refs.audioplayer
      if (a.paused) {
        a.play()
        this.isPlaying = true
      } else {
        a.pause()
        this.isPlaying = false
      }
    },
  },
}
