
import { removePTags } from '~/utils'

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isClosed: true,
      isTruncated: false,
      modalIsOpen: false,
      originalHeight: null,
    }
  },

  computed: {
    pageTitle() {
      return removePTags(this.content.page_title)
    },
  },

  mounted() {
    this.calculateTruncated()
    window.addEventListener('resize', this.calculateTruncated)
  },

  methods: {
    toggleMore() {
      document.activeElement.blur()
      this.isClosed = !this.isClosed

      if (this.isClosed) {
        this.$refs.truncatedText.style.maxHeight = this.originalHeight + 'px'
      } else {
        this.originalHeight = this.$refs.truncatedText.clientHeight
        this.$refs.truncatedText.style.maxHeight =
          this.$refs.truncatedText.scrollHeight + 'px'
      }
    },
    calculateTruncated() {
      if (!this.$refs.truncatedText) {
        window.removeEventListener('resize', this.calculateTruncated)
        return
      }
      this.isTruncated =
        this.$refs.truncatedText.clientHeight <
        this.$refs.truncatedText.scrollHeight
    },
  },
}
