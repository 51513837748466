
export default {
  components: {},

  props: {
    people: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
