import { render, staticRenderFns } from "./BubblesText.vue?vue&type=template&id=796105e8&scoped=true&"
import script from "./BubblesText.vue?vue&type=script&lang=js&"
export * from "./BubblesText.vue?vue&type=script&lang=js&"
import style0 from "./BubblesText.vue?vue&type=style&index=0&id=796105e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796105e8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,PaperPlane: require('/vercel/path0/components/icons/PaperPlane.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
