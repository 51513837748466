import { render, staticRenderFns } from "./NewsletterRegistration.vue?vue&type=template&id=44fb2e6a&scoped=true&"
import script from "./NewsletterRegistration.vue?vue&type=script&lang=js&"
export * from "./NewsletterRegistration.vue?vue&type=script&lang=js&"
import style0 from "./NewsletterRegistration.vue?vue&type=style&index=0&id=44fb2e6a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44fb2e6a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PaperPlane: require('/vercel/path0/components/icons/PaperPlane.vue').default,TextField: require('/vercel/path0/components/ui/forms/TextField.vue').default,SelectField: require('/vercel/path0/components/ui/forms/SelectField.vue').default,CheckBoxGroup: require('/vercel/path0/components/ui/forms/CheckBoxGroup.vue').default,FormBox: require('/vercel/path0/components/ui/FormBox.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
