// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/svg/border-round-red-b.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/svg/border-round-blue-b.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/png/border-round-red-b.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/images/png/border-round-blue-b.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rounded-background[data-v-56c397d4]{width:calc(100% + 4px);height:calc(100% + 4px);left:-2px;bottom:-2px;background-size:contain;background-position:100% 100%}.rounded-background.red[data-v-56c397d4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.rounded-background.yellow[data-v-56c397d4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.link-button[data-v-56c397d4]{transform:translatey(50%)}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.rounded-background.red[data-v-56c397d4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")!important}.rounded-background.yellow[data-v-56c397d4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")!important}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
