
import { getTextFromHtml } from '~/utils'

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      if (this.content.teasers.length) {
        return this.content.teasers
      }
      return this.content.items.map(({ text, url, ...item }) => ({
        text: getTextFromHtml(text),
        link_url: url,
        link_text: 'Mehr erfahren',
        ...item,
      }))
    },
  },
}
