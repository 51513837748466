
import throttle from 'lodash/throttle'
import checkIfDonatorExistsInLocalStorageMixin from '~/mixins/checkIfDonatorExistsInLocalStorageMixin'

export default {
  mixins: [checkIfDonatorExistsInLocalStorageMixin],

  props: {
    enableDonation: {
      type: Boolean,
      default: false,
    },

    max: {
      type: [String, Number],
      default: null,
    },

    min: {
      type: [String, Number],
      default: null,
    },

    donationClicksCount: {
      type: Number,
      default: 1,
    },

    currentDonatorsCount: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      maxCandleHeightInPixel: 174,
      playedAnimation: false,
      reachedGoal: false,
      candleBottomHeight: '155px',
      candleHeadLottiePlayer: null,
    }
  },

  head() {
    return {
      script: [
        {
          src: 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.12.2/gsap.min.js',
        },
      ],
    }
  },

  computed: {
    donationGoalReached() {
      return this.currentDonatorsCount >= this.donationClicksCount
    },

    getDonationStepInPixel() {
      return this.maxCandleHeightInPixel / this.donationClicksCount
    },

    isExamplePage() {
      return this.$route.path.includes('beispiel-firmenseite')
    },
  },

  async mounted() {
    await this.$nextTick()

    if (!this.enableDonation) {
      return this.revealCandleContainer()
    }

    // eslint-disable-next-line
    this.candleHeadLottiePlayer = lottie.loadAnimation({
      container: this.$refs.candleTopDesktopPlayer,
      loop: false,
      autoplay: false,
      path: '/lottie/candle-layers/candle_top.json',
    })

    this.candleHeadLottiePlayer.addEventListener('DOMLoaded', () => {
      this.registerWindowResizeEvent()
      this.setMaxCandleHeightInPixel()
      this.setInitialCandleHeight()
      this.waitForDonationClick()
      this.setContainerHeight()
      this.initCandleHeadHover()
      this.revealCandleContainer()
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setContainerHeight)
  },

  methods: {
    setInitialCandleHeight() {
      if (this.currentDonatorsCount === 0 || this.isExamplePage) return

      let donatorsCount = this.currentDonatorsCount

      if (donatorsCount > this.donationClicksCount) {
        donatorsCount = this.donationClicksCount
      }

      if (this.donationGoalReached) {
        donatorsCount = this.donationClicksCount - 1
      }

      // eslint-disable-next-line
      gsap.delayedCall(3, () => {
        this.increaseCandleHeight(
          this.getDonationStepInPixel * donatorsCount,
          true
        )
      })
    },

    increaseCandleHeight(stepInPixel = null, isInitial = false) {
      if (
        !isInitial &&
        this.currentDonatorsCount + 1 >= this.donationClicksCount
      ) {
        this.reachedGoal = true
      }

      const isDonator = this.checkIfDonatorExistsInLocalStorageMixin()

      // if goal reached and not donated yet, set candle to special height
      if (
        (this.donationGoalReached || this.reachedGoal) &&
        !isDonator &&
        !isInitial
      ) {
        const height =
          (!isInitial && stepInPixel) || this.maxCandleHeightInPixel

        // eslint-disable-next-line
        gsap.to('.candle-middle', {
          duration: 0.7,
          height,

          onComplete: () => {
            if (!isInitial) {
              this.playLottieAnimation()
              // eslint-disable-next-line
              gsap.delayedCall(3, this.scrollDownToForm)
            }
          },
        })

        return
      }

      // eslint-disable-next-line
      gsap
        .to('.candle-middle', {
        duration: 0.7,
        height:
          (isDonator && this.maxCandleHeightInPixel) ||
          stepInPixel ||
          `+=${this.getDonationStepInPixel}`,

        onComplete: () => {
          if (isInitial && !isDonator) {
            return
          }

          if (this.donationGoalReached) {
            this.playLottieAnimation()
          }

          if (isDonator || stepInPixel) {
            return
          }

          // eslint-disable-next-line
          gsap.delayedCall(1.5, this.scrollDownToForm)
        },
      })
    },

    registerWindowResizeEvent() {
      window.addEventListener('resize', this.setContainerHeight)
    },

    waitForDonationClick() {
      this.$nuxt.$on('donated', (isDonator) => {
        // set the cursor to its default state
        document
          .querySelector('.candle-container')
          .classList.add('cursor-default', 'pointer-events-none')

        // do not increase candle height if goal is reached and user is already a donator
        if (isDonator && this.donationGoalReached) {
          return
        }

        if (this.donationGoalReached) {
          this.increaseCandleHeight(this.maxCandleHeightInPixel)
          this.playLottieAnimation(true)
          return
        }

        this.increaseCandleHeight()
        this.playLottieAnimation()
      })
    },

    playLottieAnimation(goalReached = false) {
      this.candleHeadLottiePlayer.playSegments([0, 200], true)

      this.candleHeadLottiePlayer.addEventListener('complete', () => {
        this.candleHeadLottiePlayer.playSegments([50, 210], true)
      })

      if (!goalReached) {
        this.$nuxt.$emit('played')
      }
    },

    setMaxCandleHeightInPixel() {
      // eslint-disable-next-line
      gsap.delayedCall(2, () => {
        const lineTop = document.querySelector('.line-top')
        const lineBottom = document.querySelector('.line-bottom')

        if (!lineTop || !lineBottom) return

        const growFactor = 0.8
        const lineTopY = lineTop.getBoundingClientRect().y
        const lineBottomY = lineBottom.getBoundingClientRect().y

        this.maxCandleHeightInPixel = (lineBottomY - lineTopY) * growFactor
      })
    },

    initCandleHeadHover() {
      const lottieContainer = this.$refs.candleTopDesktopPlayer

      // when the mouse enters the candle head, we want to show the mouse follower
      lottieContainer
        .querySelector('svg g')
        .addEventListener('mouseenter', () => {
          this.$nuxt.$emit('candle-head-hovered', true)
        })

      // when the mouse leaves the candle head, we want to hide the mouse follower
      lottieContainer
        .querySelector('svg g')
        .addEventListener('mouseleave', () => {
          this.$nuxt.$emit('candle-head-hovered', false)
        })

      // when the candle head is clicked, we want to emit an event
      // eslint-disable-next-line
      if (Observer.isTouch && window.innerWidth < 768) {
        lottieContainer.parentElement.addEventListener('click', () => {
          this.$nuxt.$emit('donated')
        })
      } else {
        lottieContainer.querySelector('svg g').addEventListener('click', () => {
          this.$nuxt.$emit('donated')
        })
      }
    },

    revealCandleContainer() {
      // eslint-disable-next-line
      gsap
        .to(
        '.candle-container',
        {
          opacity: 1,
          height: 'auto',
          duration: 1,
          delay: 0.5,
        },
        0
      )
    },

    scrollDownToForm() {
      const donationFormTitle = document.querySelector('.donation-form-title')

      const elementRect = donationFormTitle.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.pageYOffset
      const middlePosition = absoluteElementTop - window.innerHeight / 4

      window.scrollTo({
        top: middlePosition,
        behavior: 'smooth',
      })
    },

    setContainerHeight: throttle(async function () {
      await this.$nextTick()

      const candleHead = document.querySelector('.candle-head-group')
      const candleBottom = document.querySelector('.candle-bottom')

      // eslint-disable-next-line
      gsap.to(this.$el.querySelector('.enable-donation'), {
        height: candleBottom.clientHeight - 1 + candleHead.clientHeight,
      })

      this.candleBottomHeight = candleBottom.clientHeight - 1 + 'px'
    }, 200),
  },
}
