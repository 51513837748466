
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      components: {
        image_contribution: 'ImageContribution',
        video_contribution: 'VideoContribution',
      },
    }
  },
}
