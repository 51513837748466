import { render, staticRenderFns } from "./PlusButton.vue?vue&type=template&id=9dd7503a&scoped=true&"
import script from "./PlusButton.vue?vue&type=script&lang=js&"
export * from "./PlusButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dd7503a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Minus: require('/vercel/path0/components/icons/Minus.vue').default,Plus: require('/vercel/path0/components/icons/Plus.vue').default})
