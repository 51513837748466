
import { mapState, mapMutations } from 'vuex'

export default {
  components: {},

  props: {
    podcast: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isPlaying: false,
      hasPlayed: false,
    }
  },

  computed: {
    ...mapState('player', ['playingTrack']),
    sourceType() {
      if (this.podcast.audio_file.extension === 'wav') {
        return 'audio/wav'
      } else if (this.podcast.audio_file.extension === 'ogg') {
        return 'audio/ogg'
      }
      return 'audio/mpeg'
    },
  },

  methods: {
    ...mapMutations('player', ['setPlayingTrack']),
    play() {
      this.hasPlayed = true
      this.setPlayingTrack(this.podcast.id)
      this.$nextTick(() => {
        const a = this.$refs.audioplayer
        if (a.paused) {
          a.play()
          this.isPlaying = true
        } else {
          a.pause()
          this.isPlaying = false
        }
      })
    },
  },
}
