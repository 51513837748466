
export default {
  components: {},

  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'red',
    },
  },

  computed: {
    nameLines() {
      return this.name.split('\n')
    },
    textColor() {
      switch (this.color) {
        case 'red':
          return 'text-red-200'
        case 'green':
          return 'text-green-300'
        case 'yellow':
          return 'text-yellow-500'
        default:
          return 'text-white'
      }
    },
    bgColor() {
      switch (this.color) {
        case 'red':
          return 'bg-red-200'
        case 'green':
          return 'bg-green-300'
        case 'yellow':
          return 'bg-yellow-500'
        default:
          return 'bg-white'
      }
    },
  },

  methods: {},
}
