import { render, staticRenderFns } from "./LargeArticle.vue?vue&type=template&id=4c3c4a7c&scoped=true&"
import script from "./LargeArticle.vue?vue&type=script&lang=js&"
export * from "./LargeArticle.vue?vue&type=script&lang=js&"
import style0 from "./LargeArticle.vue?vue&type=style&index=0&id=4c3c4a7c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c3c4a7c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YoutubeModal: require('/vercel/path0/components/componentloader/YoutubeModal.vue').default,HtmlText: require('/vercel/path0/components/componentloader/HtmlText.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default})
