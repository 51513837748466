import { render, staticRenderFns } from "./VinylAnim.vue?vue&type=template&id=6da4cded&scoped=true&"
import script from "./VinylAnim.vue?vue&type=script&lang=js&"
export * from "./VinylAnim.vue?vue&type=script&lang=js&"
import style0 from "./VinylAnim.vue?vue&type=style&index=0&id=6da4cded&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6da4cded",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Wave: require('/vercel/path0/components/icons/Wave.vue').default,Play: require('/vercel/path0/components/icons/Play.vue').default,Pause: require('/vercel/path0/components/icons/Pause.vue').default})
