// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/svg/border-b-red.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/svg/border-r.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/svg/border-l.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/images/png/border-r.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/images/png/border-l.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bg-border-r[data-v-ea731f08]{bottom:-1px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100% 100%}@media (min-width:1024px){.bg-border-r[data-v-ea731f08]{bottom:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:100%}}.bg-border-l[data-v-ea731f08]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:0}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.bg-border-r[data-v-ea731f08]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")!important}.bg-border-l[data-v-ea731f08]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")!important}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
