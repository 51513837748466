import { render, staticRenderFns } from "./SocialMediaButtons.vue?vue&type=template&id=0eb4bf1b&scoped=true&"
import script from "./SocialMediaButtons.vue?vue&type=script&lang=js&"
export * from "./SocialMediaButtons.vue?vue&type=script&lang=js&"
import style0 from "./SocialMediaButtons.vue?vue&type=style&index=0&id=0eb4bf1b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb4bf1b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Instagram: require('/vercel/path0/components/icons/Instagram.vue').default,FacebookRound: require('/vercel/path0/components/icons/FacebookRound.vue').default,LinkedinRound: require('/vercel/path0/components/icons/LinkedinRound.vue').default})
