import { render, staticRenderFns } from "./Journals.vue?vue&type=template&id=63fbe4a8&scoped=true&"
import script from "./Journals.vue?vue&type=script&lang=js&"
export * from "./Journals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63fbe4a8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JournalArticle: require('/vercel/path0/components/journal/JournalArticle.vue').default})
