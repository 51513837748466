
export default {
  components: {
    ComponentLoader: () => import('./ComponentLoader'),
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
    nested: {
      type: Boolean,
      default: false,
    },
    textSm: {
      type: Boolean,
      default: false,
    },
    neutralLink: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    addSpacing: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      banners: [
        'donation_banner',
        'donation_banner_simple',
        'bubble_banner',
        'large_people',
        'podcast_banner',
        'image_text',
      ],
      bannersWithTopTitle: [
        'donation_banner',
        'donation_banner_simple',
        'podcast_banner',
        'image_text',
      ],
    }
  },
  computed: {
    itemLastCount() {
      if (!this.page.items) return 0

      return this.page.items.length - 1
    },

    itemFirstCount() {
      return this.page.items[0]
    },

    isJournal() {
      return this.page.collection === 'journal'
    },
  },
  methods: {
    isBanner(item) {
      return this.banners.includes(item.type)
    },
    hasTopTitle(item) {
      return this.bannersWithTopTitle.includes(item.type) && item.title
    },
  },
}
