import { render, staticRenderFns } from "./PersonInfo.vue?vue&type=template&id=a48c4d02&scoped=true&"
import script from "./PersonInfo.vue?vue&type=script&lang=js&"
export * from "./PersonInfo.vue?vue&type=script&lang=js&"
import style0 from "./PersonInfo.vue?vue&type=style&index=0&id=a48c4d02&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a48c4d02",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PersonDetailInfos: require('/vercel/path0/components/componentloader/elements/PersonDetailInfos.vue').default,AccordionAnimation: require('/vercel/path0/components/ui/AccordionAnimation.vue').default,PlusButton: require('/vercel/path0/components/ui/PlusButton.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default})
