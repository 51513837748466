
export default {
  props: {
    content: {
      type: String,
      default: null,
    },

    fullWidth: {
      type: Boolean,
      default: false,
    },

    smallWidth: {
      type: Boolean,
      default: false,
    },

    alignCenter: {
      type: Boolean,
      default: false,
    },

    textLg: {
      type: Boolean,
      default: false,
    },

    textSm: {
      type: Boolean,
      default: false,
    },

    neutralLink: {
      type: Boolean,
      default: false,
    },

    smallTitles: {
      type: Boolean,
      default: false,
    },
  },
}
