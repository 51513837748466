
import debounce from 'lodash/debounce'

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      podcasts: [],
      search: '',
      selectedTab:
        this.content.tabs && this.content.tabs.length
          ? this.content.tabs[0].id
          : '',
      meta: {},
      loaded: false,
    }
  },

  computed: {
    debounceSearch() {
      return debounce(() => this.fetchPodcasts(), 300)
    },
  },

  watch: {
    selectedTab() {
      this.fetchPodcasts()
    },
  },

  created() {
    if (this.$route.query.search) {
      this.search = this.$route.query.search
    }
  },

  mounted() {
    this.fetchPodcasts()
  },

  methods: {
    async fetchPodcasts() {
      const { data, meta } = await this.$axios.$get(`podcasts`, {
        params: {
          search: this.search,
          radio_show: this.selectedTab,
          items: 4,
        },
      })
      this.podcasts = data
      this.meta = meta
      this.loaded = true
    },
    async loadMore() {
      document.activeElement.blur()
      const { data, meta } = await this.$axios.$get(`podcasts`, {
        params: {
          search: this.search,
          radio_show: this.selectedTab,
          items: 4,
          page: this.meta.current_page + 1,
        },
      })
      this.podcasts.push(...data)
      this.meta = meta
    },
  },
}
