import { render, staticRenderFns } from "./JournalPage.vue?vue&type=template&id=07d3c3ec&"
import script from "./JournalPage.vue?vue&type=script&lang=js&"
export * from "./JournalPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {JournalFilter: require('/vercel/path0/components/journal/JournalFilter.vue').default,Journals: require('/vercel/path0/components/journal/Journals.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,NewsletterRegistration: require('/vercel/path0/components/componentloader/NewsletterRegistration.vue').default})
