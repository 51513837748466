
import debounce from 'lodash/debounce'

export default {
  components: {},

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      search: '',
      donationStories: [],
      meta: {},
      loaded: false,
    }
  },

  computed: {
    debounceSearch() {
      return debounce(() => this.loadStories(), 300)
    },
    mapedDonationStories() {
      return this.donationStories.map((story) => ({
        ...story,
        image: story[story.show_image?.key || 'image1'],
        name: story.allowPublicationWithName
          ? `${story.firstname || ''} ${story.lastname || ''}`
          : null,
      }))
    },
  },

  mounted() {
    this.loadStories()
  },

  methods: {
    async loadStories() {
      this.donationStories = await this.fetchStories()
      this.loaded = true
    },
    async loadMoreStories() {
      document.activeElement.blur()
      const stories = await this.fetchStories(this.meta.current_page + 1)
      this.donationStories.push(...stories)
    },
    async fetchStories(page = 1) {
      const response = await this.$axios
        .$get('donation-stories', {
          params: {
            search: this.search,
            page,
            per_page: 9,
          },
        })
        .catch(() => {})

      if (response) {
        this.meta = response.meta
        return response.data
      }
    },
  },
}
