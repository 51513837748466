
import { mapState } from 'vuex'

export default {
  data() {
    return {
      likedJournals: [],
      isLoading: false,
      moreJournals: [],
      authorDetailsOpen: false,
    }
  },

  computed: {
    ...mapState('page', ['page']),

    hasLiked() {
      return this.likedJournals.includes(this.page.id)
    },
  },

  mounted() {
    this.likedJournals = localStorage.getItem('likedJournals')
      ? JSON.parse(localStorage.getItem('likedJournals'))
      : []

    this.loadMoreJournals()
  },

  methods: {
    like() {
      this.isLoading = true

      this.$axios
        .$post(`journals/${this.page.id}/like`, {
          like: !this.hasLiked,
        })
        .then(() => {
          this.$store.commit('page/setPage', {
            ...this.page,
            likes_count:
              (this.page.likes_count || 0) + (this.hasLiked ? -1 : 1),
          })
          if (this.hasLiked) {
            const index = this.likedJournals.indexOf(this.page.id)
            this.likedJournals.splice(index, 1)
          } else {
            this.likedJournals.push(this.page.id)
          }
          this.likedJournals = [...this.likedJournals]

          localStorage.setItem(
            'likedJournals',
            JSON.stringify(this.likedJournals)
          )
          this.isLoading = false
        })
    },

    async loadMoreJournals() {
      const { data } = await this.$axios.$get(
        `journals/${this.page.id}/related`,
        {
          params: {},
        }
      )

      this.moreJournals = data
    },
  },
}
