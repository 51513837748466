
export default {
  components: {},

  props: {
    quotes: {
      type: Array,
      required: true,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  computed: {},

  methods: {},
}
