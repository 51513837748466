
export default {
  props: {
    page: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      notDonatedYet: true,
      showSuccessMessage: false,
      formData: {
        button_text: 'Bestellung abschliessen',
        text_below_button:
          'Sie erhalten umgehend eine Bestellbestätigung per E-Mail. Ihre persönliche Landingpage wird innerhalb von 5 Arbeitstagen nach Bestellung aktiviert. Nach der Aktivierung erhalten Sie ebenfalls eine Bestätigung. Falls Sie später Änderungen an den Texten vornehmen möchten, können Sie uns dies per E-Mail an info@denkanmich.ch oder telefonisch unter 058 135 06 50 (Mo-Do jeweils vormittags) mitteilen.',
        submition_form: [this.page.form],
      },
    }
  },

  computed: {
    persons() {
      const isExamplePage = this.$route.path.includes('beispiel-firmenseite')

      if (isExamplePage) {
        return [
          'Max Muster',
          'Ammann AG',
          'Anna Meier',
          'Laura Herzog',
          'Meyer & Co.',
          'Stefan Graf',
          'Nina Schneider',
          'Daniel Weber',
          'Simone Berger',
          'Peter Müller',
          'Sandra Bär',
          'Schweizerische Versicherungen',
          'Andreas Schmid',
          'Markus Zimmermann',
          'Nicole Moser',
          'Müller & Söhne',
          'Christian Beck',
          'Sarah Fischer',
          'Lena Wagner',
          'Schmidt GmbH',
          'SwissTech Solutions',
          'Thomas Huber',
          'Schweizer Uhrenfabrik',
          'Schweizer Bank',
          'Marco Brunner',
          'Schweizer Versandhaus',
          'Susanne Lehmann',
          'Michael Koch',
          'Zürich Versandhaus',
        ]
      } else {
        return this.page.donators.map((donator) => donator.name)
      }
    },
  },

  created() {
    this.$nuxt.$emit('set-white-background', true)
    this.$nuxt.$on('donated', () => {
      this.notDonatedYet = false
    })
  },

  beforeDestroy() {
    this.$nuxt.$emit('set-white-background', false)
  },
}
