
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    toggleTag(tag) {
      const selectedTags = [...this.value]
      const index = selectedTags.indexOf(tag)

      if (index >= 0) {
        selectedTags.splice(index, 1)
      } else {
        selectedTags.push(tag)
      }

      this.$emit('input', selectedTags)
    },
  },
}
