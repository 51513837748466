
export default {
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
    componentLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedTab: 0,
      form: {},
      genders: [
        {
          label: 'Mann',
          value: 'm',
        },
        {
          label: 'Frau',
          value: 'f',
        },
        {
          label: 'Neutrale Anrede',
          value: 'n',
        },
      ],
      websiteAuthorizations: [
        {
          label: 'Mit Angabe von Name und Wohnort',
          value: true,
        },
        {
          label: 'Anonym, ohne Angabe von Name und Wohnort',
          value: false,
        },
      ],
      radioAuthorizations: [
        {
          label: 'Mit Angabe von Name und Wohnort',
          value: true,
        },
        {
          label: 'Anonym, ohne Angabe von Name und Wohnort',
          value: false,
        },
      ],
      isLoading: false,
    }
  },
  watch: {
    selectedTab() {
      const rect = this.$refs.donationStoryForm.getBoundingClientRect()
      window.scrollTo(0, rect.top + window.scrollY - 150)
    },
  },
  methods: {
    goBack() {
      document.activeElement.blur()
      this.selectedTab--
    },
    submitStory() {
      this.isLoading = true

      const formData = new FormData()
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] !== null) {
          formData.append(key, this.form[key])
        }
      })
      this.$axios
        .$post('donation-stories', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.selectedTab = 6
        })
        .catch(() => {
          this.selectedTab = 5
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
